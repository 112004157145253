.companies_grid {
  gap: 16px;
  grid-template-rows: auto auto;
  grid-auto-columns: 1fr;
  display: grid;
  @media(min-width: 460px){
    grid-template-columns: 1fr 1fr;
  }

  @media(min-width: 590px){
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.company_card {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: start;
    pointer-events: auto;
    background-color: #fff;
    border: 1px rgba(29,36,69,.1);
    border-radius: 10px;
    margin-top: 15px;
    padding: 20px;
    text-decoration: none;
    transition: all .3s;
    position: relative;
    overflow: hidden;
}
