.jobs .job_list_wrapper {
  margin-top: -200px;
}

.jobs-available {
color: #848484;
}

.jobs_section {
  @media (max-width: 479px) {
    padding: 60px 3%;
  }

  @media screen and (max-width: 991px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  z-index: 3;
  background-color: white;
  padding: 100px 5%;
  position: relative;
}

.job_list_wrapper {
  @media (max-width: 991px) {
    margin-top: -160px;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

  @media (max-width: 479px) {
    padding: 0 3%;
  }

  max-width: 910px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #e5eaf4;
  border-radius: 16px;
  margin-bottom: 50px;
  padding: 17px 72px 32px;
  box-shadow: 0px 75px 150px rgba(52, 64, 84, 0.14);
  backdrop-filter: blur(20px);
}

.job_list {
  display: grid;
  width: 100%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  outline-offset: 0px;
  cursor: pointer;
  mix-blend-mode: normal;
  outline: 3px #00e;
  text-decoration: none;
  transition: opacity .2s;
}

.job_card {
  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
}

  @media screen and (max-width: 479px) {
    padding: 20px 15px;
  }

  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  border: 1px rgba(29,36,69,.1);
  border-radius: 10px;
  margin-top: 15px;
  padding: 24px 28px;
  text-decoration: none;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  &:hover {
    background: #efefef;
  }
}

.headline {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  @media(min-width: 700px){
      flex-wrap: nowrap;
  }
}

.job_title {
    color: #051029;
    text-transform: capitalize;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4rem;
    margin-top: 0 !important;
    letter-spacing: -.5px;
    
    .jobs-available {
      font-weight: normal !important;
    }
}

.job_company {
  color: #425466;
  margin-top: 5px;
  padding-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}

.badges_wrapper {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-right: 0;
  display: flex;
  margin-top: 5px;
}

.job_meta_wrapper {
  background-color: #e4ecf7;
  border-radius: 6px;
  align-items: center;
  margin: 0 8px 0 0;
  padding: 5px 8px;
  display: flex;
  gap: 5px;
  color: #505780;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 23px;
}

.job_description_wrapper {
  max-width: 450px;
  float: right;
  clear: none;
  outline-offset: 0px;
  text-align: right;
  object-fit: fill;
  outline: 3px #051029;
  flex: 1;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  position: static;
  color: #425466;
  font-size: 16px;
  line-height: 23px;
  padding-left: 20px;
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.job_post.container {
  overflow: visible;
  width: 100%;
  max-width: 1150px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3%;
  padding-right: 3%;
  margin-top: -370px;
 // overflow: hidden;
  gap: 30px;
  margin-bottom: 80px;
  grid-template-rows: auto;
  position: relative;
  @media(min-width: 992px){
    margin-top: -320px;
    grid-template-columns: 2fr minmax(200px,280px);
  }

  grid-auto-columns: 1fr;
  justify-items: stretch;
  display: grid;
}

.job_page_content {
  display: grid;
  border-radius: 12px;
 // box-shadow: 0px 75px 150px rgba(52, 64, 84, 0.08);
}

.job_sidebar {
  height: fit-content;
  backdrop-filter: blur(20px);
    background-color: rgba(255,255,255,.9);
    border-width: 1px;
    border-color: rgba(255,255,255,.9);
    border-radius: 16px;
    padding: 32px;
    transition-property: none;
    box-shadow: 0 0 35px 10px rgba(0,0,0,.03);
    align-items: stretch;
    display: grid;
    gap: 10px;
    .social {
      display: flex;
      gap: 7px;
      margin-bottom: 30px;
    }
}

.job-summary-item {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  font-weight: 400;
  display: flex;
}

.category_label {
  color: #183b56;
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 15px;
}

.category_value {
  color: #5a7184;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 100%;
}

.share_label {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.button_toc {
  margin-bottom: 20px;
  padding: 12px 16px;
  border-radius: 999px;
  text-decoration: none;
  text-align: center;
  width: 100%;
  display: block;
  color: #000;
  background-color: orange;
}

.job_heading.single {
  padding: 30px 40px 20px;
  background: rgba(255, 255, 255, 0.9);
}

.job_heading {
  width: 100%;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  border-radius: 16px;
  align-items: center;
  margin-right: 0;
  margin-bottom: 12px;
  h1 {
    margin-top: 0;
    margin-bottom: 20px;
    font-family: system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Noto Sans,Ubuntu,Cantarell,Helvetica Neue,Oxygen,Fira Sans,Droid Sans,sans-serif;
    font-size: 32px;
    line-height: 100%;
    font-weight: 700;
    color: #051029;
  }
  img {
    border-radius: 4px;
  }
}

.job_post_page_hero {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.company_link {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.job-post-main-content {
  background-color: rgba(255,255,255,.9);
  border: 1px solid #e5eaf4;
  backdrop-filter: blur(20px);
  box-shadow: 0px 75px 150px rgba(52, 64, 84, 0.08);
  border-radius: 16px;
  margin-top: 18px;
  padding: 40px;
  h2 {
    font-size: 21px;
    line-height: 24px;
    font-weight: 700;
    margin: 30px 0 20px;
  }

  p {
    color: rgba(29,36,69,.75);
    text-align: left;
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    font-weight: 300;
  }

  ul, ol {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px;
    font-size: 16px !important;
    color: rgba(29, 36, 69, 0.75) !important;
  }

  ul {
    list-style: inside;
  }

  li {
    margin-bottom: 4px;
    font-weight: 300;
  }
}

.job_single {
  .navigation_outer {
    position: fixed;
  }

.newsletter-wrapper.larger {
  max-width: 1300px;
}

.max-w-width.newsletter-form {
  background-color: transparent;
  p {
    color: #000;
  }

  .submit {
    color: #fff;
    background-color: #f58700;
  }
}

.newsletter-wrapper {
  max-width: 1055px;
  margin-left: auto;
  margin-right: auto;
}

.inline-container {
  display: block;
  background: #fff;
  border: 1px solid #f2f2f3;
  border-radius: 10px;
  padding: 40px 20px 30px 20px;
  max-width: 1072px;
  margin: 0 auto 60px;
}

.eo-form-wrapper {
  min-width: 250px;
  h2 {
    font-weight: 600;
    font-size: 32px;
  }
}

.main-form {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  #field_0 {
    display: block;
    min-width: 200px;
    background-color: #fff;
    line-height: 1.6;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #495057;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
  }
}

btn-primary {
  color: #fff;
  background-color: #faac00;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}

.emailoctopus-success-message {
  display: none;  
  }
}

.similar_jobs_section {
  margin-bottom: 200px !important;
  .s_title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .job_inner {
    display: grid;
    gap: 10px;
  }
}