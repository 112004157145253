.companies_grid {
  max-width: 1055px;
  margin: 70px auto 20px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-auto-columns: 1fr;
  display: grid;
  @media(min-width: 560px){
    grid-template-columns: (1fr 1fr);
  }

  @media(min-width: 800px){
    grid-template-columns: (1fr 1fr 1fr);
  }
}

.company_card {
  background-color: white;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: all .2s;
  display: flex;
  box-shadow: 0 0 65px -45px rgba(0,0,0,.2);
}

.company_card_link {
  display: grid;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #051029;
  text-align: center;
  padding: 20px;
  text-decoration: none;
  img {
    display: inline-block;
    margin: 0 auto 20px;
  }
}

.company_name {
  font-size: 16px;
  font-weight: 600;
}