.page_outer {
  background-color: #f4f4f6;
}

.page_blog_outer .page_heading {
  margin-top: -10px;
  margin-bottom: 70px;
  z-index: 2;
}

.blog_outer {
  padding: 20px 16px;
}

.blog_hero {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 75px;
  height: 240px;
  background: #fff;
}

.button.second {
  height: fit-content;
  background-color: #051029;
  color: #fff;
  border-style: none;
  text-align: center;
  letter-spacing: .25px;
  text-transform: capitalize;
  border-radius: 36px;
  padding: 12px 18px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  transition: all .3s;
  position: relative;
  width: fit-content;
}

.meta-text {
  color: #051029;
  text-transform: capitalize;
  text-decoration: none;
  transition: all .3s;
}

.blog-title-text {
  color: #051029;
  text-transform: capitalize;
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  text-decoration: none;
  transition: all .3s;
  display: block;
}

.blog-grid {
  max-width: 912px;
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  @media(min-width: 768px){
    grid-template-columns: 1fr 1fr;
  }

  @media(min-width: 992px){
    margin-top: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 80px;
  }
}

.markdown-body .related_posts {
  margin-bottom: 100px !important;
  related_post_title {
    text-align: center;
  }

  h3 a {
    color: #000;
  }
}