.container {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
}

h2 {
  font-weight: bold;
  font-size: 28px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.warning {
  color: red;
  text-align: center;
  margin: 30px auto;
  font-weight: 600;
}

.places, .companies, .categories {
  background-color: rgba(67,83,255,.05);
}

.page_outer {
  position: relative;
  padding-top: 96px;
  padding-bottom: 96px;
  overflow: hidden;
  @media(min-width: 768px){
    padding-top: 112px;
    padding-bottom: 112px;
  }
}

.page_inner {
  padding-left: 16px;
  padding-right: 16px;
}

.post_outer {
  padding-left: 5%;
  padding-right: 5%;
}

.post_inner {
  max-width: 640px;
  background: transparent;
}

.page_heading {
  position: relative;
  margin: 32px auto 20px;
  text-align: center;
  h1 {
    text-transform: capitalize;
    margin-top: 35px;
    font-size: 38px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -.5px;
    margin-bottom: 10px;
  }

  p {
    margin-top: 32px;
  }
}

table th {
  text-align: left;
}