.max-w-width.newsletter-form{
  max-width: 1092px;
  padding: 30px 20px 20px;
  background-color: #111;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 12px;
  @media(min-width:992px) {
    margin-bottom: 80px;
  }

  p {
    color: #fff;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.main-form {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
}

.form-control {
  padding: 12px;
  min-height: 47px;
  border: 1px solid rgba(47, 43, 67, 0.1);
  box-shadow: 0px 1px 3px rgba(47, 43, 67, 0.1), inset 0px -1px 0px rgba(47, 43, 67, 0.1);
  border-radius: 12px;
}

.submit {
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  background: #2F2B43;
  box-shadow: 0px 1px 3px rgba(47, 43, 67, 0.1), inset 0px -1px 0px rgba(47, 43, 67, 0.1);
  border-radius: 12px;
  letter-spacing: 0;
  text-align: center;
  transition: 0.2s;
  margin-bottom: 8px;
  width: 100%;
  color: #fff;
  @media(min-width: 460px){
    width: fit-content;
  }
}