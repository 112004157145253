.job_categories {
  padding: 50px 5% 100px;
  min-height: 550px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.categories_wrapper {
  max-width: 1055px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-auto-columns: 1fr;
  display: grid;
  @media(min-width: 768px){
    grid-template-columns: 1fr 1fr;
  }

  @media(min-width: 992px){
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.category_card {
  background-color: white;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: all .2s;
  display: flex;
  box-shadow: 0 0 65px -45px rgba(0,0,0,.2);
}

.category_card_link {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 20px;
  text-decoration: none;
}

// single category
.current_category_companies {
  max-width: 984px;
  margin: 32px auto;
  display: grid;
  .job_card {
    width: 100%;
    pointer-events: auto;
    margin-top: 15px;
    padding: 24px 28px;
    text-decoration: none;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    .job_inner {
      display: grid;
      gap: 20px;
    }
  }
}
