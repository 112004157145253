.hidden_small {
  @media(max-width: 991px) {
    position: absolute;
    top: -100%;
  }
}

.menu_toggler {
  position: fixed;
  right: 5%;

  @media(min-width: 992px) {
    display: none;
  }
}

.hamburger {
  position: relative;
  width: 24px;
  height: 20px;
}

.hamburger span {
  position: absolute;
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: 3.4px;
  left: 0;
  top: 8px;
  border-radius: 10px;
  transition: all 0.3s ease 0s;
}

.hamburger:before,
.hamburger:after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 3px;
  left: 0;
  border-radius: 10px;
  transition: all 0.3s ease 0s;
}

.dark {
  .education a {
    color: rgb(187, 255, 0);
  }

  .hamburger span,
  .hamburger:before,
  .hamburger:after {
    background-color: #fff;
  }
}

.hamburger:before {
  height: 3.4px;
  top: 0;
  border-radius: 10px;
}

.hamburger:after {
  bottom: 0;
}

.hamburger.active-burger:before {
  transform: rotate(45deg);
  top: 8px;
}

.hamburger.active-burger:after {
  transform: rotate(-45deg);
  bottom: 9px;
}

.hamburger.active-burger span {
  transform: scale(0);
}

@media(max-width: 1023px){
  .slideInBottom {
    -webkit-animation: slideInBottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slideInBottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .slideOutUp {
      -webkit-animation-name: slideOutUp;
      animation-name: slideOutUp;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      }
  }

   @-webkit-keyframes slideInBottom {
    0% {
      -webkit-transform: translateY(-200px);
              transform: translateY(-200px);
    }
  
    100% {
      -webkit-transform: translateY(40px);
              transform: translateY(40px);
    }
  }
  
  @keyframes slideInBottom {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
  
    100% {
      -webkit-transform: translateY(40px);
              transform: translateY(40px);
    }
  }

    @-webkit-keyframes slideOutUp {
    0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    }

    100% {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    }
    }

    @keyframes slideOutUp {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    }

    100% {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    }
    } 
  