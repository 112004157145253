.hero {
  margin-top: 75px;
  padding: 60px 5%;
  background-image: url(/assets/images/hero-bg.png);
  background-position: 40%;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  text-align: center;
  @media(min-width:480px){
    height: auto;
    padding-left: 5%;
    padding-right: 5%;
  }

  @media(min-width:922px){
    padding: 0;
  }
}

.hero_inner {
  padding: 30px 0% 60px;
  color: #fff;

  @media (min-width: 480px) {
    .hero-grid {
      grid-template-columns: 1fr;
      padding-bottom: 160px;
    }
  }

  @media (min-width: 992px) {
    z-index: 2;
    max-width: 1200px;
    gap: 10px;
    grid-template-rows: auto;
    padding: 110px 5% 140px;
    display: grid;
    position: relative;
  }
}

.hero_title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 35px;
  line-height: 40px;
  font-weight: 500;

  @media(min-width:480px) {
    font-size: 45px;
    line-height: 50px;
  }

  @media(min-width:768px) {
    font-size: 45px;
    line-height: 55px;
  }

  @media(min-width:991px) {
    font-size: 54px;
    font-weight: 500;
    line-height: 68px;
  }
}

.hero_subtitle {
  max-width: 495px;
  color: white;
  text-align: center;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.55;
}

.hero_section {
  min-height: 575px;
  background-image: url(/assets/images/hero-bg.png);
  background-attachment: scroll!important;
  background-position: 40%;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  z-index: -1;
}

.job_single .hero_section {
  margin-top: -75px;
}

.job_single .nobg {
  background-color: transparent !important;
}
