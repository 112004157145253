.hero_section_company {
  min-height: 130px;
 background-image: linear-gradient(180deg,#051029,#0a092d 7%,#713b2a 45%,#dd652b);
  position: relative;
}

.company_top_logo {
  margin: 0px auto 0;
  display: block;
  position: relative;
  z-index: 10;
  border: 2px solid #000;
  border-radius: 50%;
  box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.4);
}

.company_header {
  gap: 28px;
  margin-bottom: 40px;
  text-align: center;
  h1 {
    color: #051029;
    text-transform: capitalize;
    margin-top: 10px;
    font-size: 38px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0.7px;
    }

    p {
      max-width: 600px;
      color: #555;
      text-align: center;
      margin-bottom: 15px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.5;
    }
}

.category-ticket-list {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
  display: flex;
  a {
    display: block;
  }
}

.company_section {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  @media(min-width:992px){
    padding-top: 30px;
    padding-bottom: 60px;
  }
}

.job_header {
  justify-content: space-between;
  align-items: start;
  display: grid;
  .part_1 {
    width: 100%;
    display: grid;
    gap: 10px;
  }

  @media(min-width: 425px){
    & {grid-template-columns: 1fr 90px}
    .part_1 {display: flex;}
  }
}

.current_companies {
  max-width: 984px;
  margin: 32px auto;
  display: grid;
  gap: 20px;
  padding-bottom: 40px;
  .job_card {
    width: 100%;
    pointer-events: auto;
    //background-color: #fff;
    border: 1px rgba(29,36,69,.1);
    border-radius: 10px;
    margin-top: 15px;
    padding: 24px 28px;
    text-decoration: none;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    .job_inner {
      display: grid;
      gap: 20px;
    }
  }
}

.button_wrapper {
  width: fit-content;
    background-color: orange;
    text-align: center;
    letter-spacing: .25px;
    border-radius: 36px;
    justify-content: center;
    align-items: center;
    padding: 12px 18px;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    transition: all .3s;
    position: relative;
}

.job_labels_box {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.job_label {
  background-color: #e4ecf7;
  border-radius: 6px;
  align-items: center;
  margin: 0 8px 0 0;
  padding: 2px 8px;
  display: flex;
  gap: 8px;
  color: #505780;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 23px;
}