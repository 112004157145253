.navigation_outer {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  padding-left: 5%;
  padding-right: 5%;
  top: 0;
  background-color: #051029;
  z-index: 30;
}

.navigation_inner {
  width: 100%;
  max-width: 1100px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  font-weight: 600;
  text-transform: capitalize;
}

.header_logo_outer {
  order: -1;
  align-items: center;
  display: flex;
  position: absolute;
  left: 5%;
  top: 10px;

  @media(min-width:992px) {
    width: auto;
    position: static;
    display: block;
    justify-content: start;
  }
}

.navigation_list {
  background-color: #fff;
  position: absolute;
  top: 38px;
  left: 5%;
  right: 5%;
  @media(min-width: 992px) {
    position: static;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: fit-content;
    background-color: transparent;
    color: #fff;
  }
}

.navigation_link {
  text-transform: capitalize;
  border: 1px solid transparent;
  border-radius: 36px;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
  padding: 10px 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  transition: all .2s;
  display: flex;

  &.active,
  &:hover {
    border: 1px solid #fff;
  }
}

.button_post {
  display: none;

  @media(min-width: 992px) {
    display: inline-block;
    background-color: #f4f4f6;
    color: #051029;
    text-align: center;
    letter-spacing: .25px;
    text-transform: capitalize;
    border-radius: 36px;
    justify-content: center;
    align-items: center;
    padding: 12px 18px;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    transition: all .3s;
    position: relative;
  }
}

body {
  font-family: Numbers, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
}

body p {
  font-weight: 300;
}