.footer {
  color: white;
  background-color: #051029;
  padding: 48px 5%;
}

.footer_inner {
  max-width: 1200px;
  z-index: 1;
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.footer_list {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.footer_link {
  color: #fff;
  mix-blend-mode: normal;
  margin-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;
  transition: all .2s;
  display: block;
}

.small_footer {
  text-align: center;
  width: 100%;
  background-color: whitesmoke;
  color: rgba(47,43,67,.6);
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  font-weight: 400;
  display: flex;
}

.col_1 {
  margin-right: auto;
  margin-left: auto;
}

.col_3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  margin-right: auto;
  margin-left: auto;
}

.social-icon-box {
  width: 35px;
  background-color: #051029;
  border-radius: 20px;
  padding: 8px;
  transition: all .3s;
}